const Project = () => import(/* webpackChunkName: "Dashboard" */ "./index")
const ProjectStatus = () => import(/* webpackChunkName: "ProjectStatus" */ "./Status")
const ProjectDealsTable = () => import(/* webpackChunkName: "ProjectDealsTable" */ "./Deals/DealsTable")
const DealGeneral = () => import(/* webpackChunkName: "DealGeneral" */ "@/containers/Deal/General")
const DealActivitiesTable = () =>
  import(/* webpackChunkName: "DealActivitiesTable" */ "@/containers/Deal/Activities/ActivitiesTable")
const DealStand = () => import(/* webpackChunkName: "DealStand" */ "@/containers/Deal/Stand")
const ProjectActivitiesTable = () =>
  import(/* webpackChunkName: "ProjectActivitiesTable" */ "./Activities/ActivitiesTable")
const Activity = () => import(/* webpackChunkName: "Activity" */ "@/containers/Activity")
const ExhibitorSignups = () => import(/* webpackChunkName: "ExhibitorSignups" */ "./ExhibitorSignups")
const ProjectSetup = () => import(/* webpackChunkName: "ProjectSetup" */ "./Setup")
const ProjectHeaderTitle = () =>
  import(/* webpackChunkName: "ProjectSetup" */ "@/containers/Project/ProjectHeaderTitle")
const DealHeaderTitle = () => import(/* webpackChunkName: "DealHeaderTitle" */ "@/containers/Deal/DealHeaderTitle")
const ActivityHeaderTitle = () =>
  import(/* webpackChunkName: "ActivityHeaderTitle" */ "@/containers/Activity/ActivityHeaderTitle")
const Sidebar = () => import(/* webpackChunkName: "Sidebar" */ "@/components/TheSidebar")
const Header = () => import(/* webpackChunkName: "Header" */ "@/components/TheHeader")
const DealExtraOrders = () => import(/* webpackChunkName: "DealServices" */ "@/containers/Deal/ExtraOrders")
const DealHistory = () => import(/* webpackChunkName: "DealHistory" */ "@/containers/Deal/History")
const Invoicing = () => import(/* webpackChunkName: "Invoicing" */ "@/containers/Project/Invoicing")
const ProjectExtraOrders = () => import(/* webpackChunkName: "ProjectExtraOrders" */ "@/containers/Project/ExtraOrders")

export default {
  path: "/project/:id",
  name: "project",
  redirect: { name: "project-status" },
  components: {
    default: Project,
    sidebar: Sidebar,
    header: Header
  },
  meta: {
    navigationTabsKey: "project"
  },
  children: [
    {
      path: "status",
      name: "project-status",
      props: true,
      components: { default: ProjectStatus, dynamicHeaderTitle: ProjectHeaderTitle },
      meta: {
        breadcrumb: "project status",
        requiredHeaderDynamicComponents: ["HistoricProjectsCheckbox", "ProjectsDropdown", "ProjectsDropdownOptions"]
      }
    },
    {
      path: "ExhibitorSignups",
      name: "project-exhibitor-signups",
      props: true,
      components: { default: ExhibitorSignups, dynamicHeaderTitle: ProjectHeaderTitle },
      meta: {
        breadcrumb: "project exhibitorsignups",
        requiredHeaderDynamicComponents: ["HistoricProjectsCheckbox", "ProjectsDropdown", "ProjectsDropdownOptions"]
      }
    },
    {
      path: "setup",
      name: "project-setup",
      props: true,
      components: { default: ProjectSetup, dynamicHeaderTitle: ProjectHeaderTitle },
      meta: {
        breadcrumb: "project setup",
        requiredHeaderDynamicComponents: ["HistoricProjectsCheckbox", "ProjectsDropdown", "ProjectsDropdownOptions"]
      }
    },
    {
      path: "extra-orders",
      name: "project-extra-orders",
      props: true,
      components: { default: ProjectExtraOrders, dynamicHeaderTitle: ProjectHeaderTitle },
      meta: {
        breadcrumb: "extra orders",
        requiredHeaderDynamicComponents: ["HistoricProjectsCheckbox", "ProjectsDropdown", "ProjectsDropdownOptions"]
      }
    },
    {
      path: "deals",
      name: "project-deals",
      meta: {
        breadcrumb: "project deals"
      },
      redirect: { name: "project-deals-table" },
      children: [
        {
          path: "",
          name: "project-deals-table",
          props: true,
          components: { default: ProjectDealsTable, dynamicHeaderTitle: ProjectHeaderTitle },
          meta: {
            requiredHeaderDynamicComponents: ["HistoricProjectsCheckbox", "ProjectsDropdown", "ProjectsDropdownOptions"]
          }
        },
        {
          path: ":dealId",
          name: "project-deal",
          meta: {
            navigationTabsKey: "project-deal"
          },
          redirect: { name: "project-deal-general" },
          children: [
            {
              path: "general",
              name: "project-deal-general",
              props: true,
              components: { default: DealGeneral, dynamicHeaderTitle: DealHeaderTitle },
              meta: {
                breadcrumb: "deal general"
              }
            },
            {
              path: "activities",
              name: "project-deal-activities",
              meta: {
                breadcrumb: "deal activities"
              },
              redirect: { name: "project-deal-activities-table" },
              children: [
                {
                  path: "",
                  name: "project-deal-activities-table",
                  props: true,
                  components: { default: DealActivitiesTable, dynamicHeaderTitle: DealHeaderTitle }
                },
                {
                  path: ":activityId",
                  name: "project-deal-activity",
                  props: true,
                  components: { default: Activity, dynamicHeaderTitle: ActivityHeaderTitle },
                  meta: {
                    navigationTabsKey: null,
                    breadcrumb: "activity"
                  }
                }
              ]
            },
            {
              path: "stand",
              name: "project-deal-stand",
              props: true,
              components: { default: DealStand, dynamicHeaderTitle: DealHeaderTitle },
              meta: {
                breadcrumb: "deal stand"
              }
            },
            {
              path: "extra-orders",
              name: "project-deal-extra-orders",
              props: true,
              components: { default: DealExtraOrders, dynamicHeaderTitle: DealHeaderTitle },
              meta: {
                breadcrumb: "extra orders"
              }
            },
            {
              path: "history",
              name: "project-deal-history",
              props: true,
              components: { default: DealHistory, dynamicHeaderTitle: DealHeaderTitle },
              meta: {
                breadcrumb: "history"
              }
            }
          ]
        }
      ]
    },
    {
      path: "activities",
      name: "project-activities",
      meta: {
        breadcrumb: "project activities"
      },
      redirect: { name: "project-activities-table" },
      children: [
        {
          path: "",
          name: "project-activities-table",
          props: true,
          components: { default: ProjectActivitiesTable, dynamicHeaderTitle: ProjectHeaderTitle },
          meta: {
            requiredHeaderDynamicComponents: ["HistoricProjectsCheckbox", "ProjectsDropdown", "ProjectsDropdownOptions"]
          }
        },
        {
          path: ":activityId",
          name: "project-activity",
          props: true,
          components: { default: Activity, dynamicHeaderTitle: ActivityHeaderTitle },
          meta: {
            navigationTabsKey: null,
            breadcrumb: "activity"
          }
        }
      ]
    },
    {
      path: "invoicing",
      name: "project-invoicing",
      props: true,
      components: { default: Invoicing, dynamicHeaderTitle: ProjectHeaderTitle },
      meta: {
        breadcrumb: "invoicing",
        requiredHeaderDynamicComponents: ["HistoricProjectsCheckbox", "ProjectsDropdown", "ProjectsDropdownOptions"]
      }
    }
  ]
}
