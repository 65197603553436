<template>
  <div class="flex flex-col space-y-5 text-start p-4">
    <slot name="title"></slot>
    <div class="flex-col">
      <label class="label"> Team name </label>
      <input
        class="input mt-1"
        :class="{ 'input--invalid': v$.name.$error }"
        v-model="editedTeam.name"
        v-select-text
        @blur="v$.name.$touch"
        data-test="team-name-input"
      />
      <div v-if="v$.name.$error" class="text-sm text-red-700">{{ v$.name.$errors[0].$message }}</div>
    </div>
    <div class="flex space-x-2 mt-4">
      <div class="flex-col">
        <label class="label"> Team start date </label>
        <DatePicker
          v-model="editedTeam.start_date"
          class="mt-1"
          :error="v$.start_date.$error"
          @update:modelValue="v$.start_date.$touch"
        />
        <div v-if="v$.start_date.$error" class="text-sm text-red-700">{{ v$.start_date.$errors[0].$message }}</div>
      </div>
      <div class="flex-col">
        <label class="label"> Team end date </label>
        <DatePicker
          v-model="editedTeam.end_date"
          class="mt-1"
          :error="v$.end_date.$error"
          @update:modelValue="v$.end_date.$touch"
        />
        <div v-if="v$.end_date.$error" class="text-sm text-red-700">{{ v$.end_date.$errors[0].$message }}</div>
      </div>
    </div>
    <div class="flex space-x-2 mt-4">
      <div class="flex-col">
        <label class="label"> Sale goal (NOK) </label>
        <input
          v-model="editedTeam.total_sale_goal_nok"
          v-select-text
          data-test="goal-nok-input"
          class="input mt-1"
          :class="{ 'input--invalid': v$.total_sale_goal_nok.$error }"
          type="number"
          min="0"
          oninput="validity.valid||(value='')"
          @blur="v$.total_sale_goal_nok.$touch"
        />
        <div v-if="v$.total_sale_goal_nok.$error" class="text-sm text-red-700">
          {{ v$.total_sale_goal_nok.$errors[0].$message }}
        </div>
      </div>
    </div>
    <div class="flex justify-center space-x-2 pb-2">
      <button class="button button-bg-light" @click="closeModal" data-test="cancel-btn">Cancel</button>
      <button class="button" @click="onUpdateTeam" data-test="confirm-btn">Save</button>
    </div>
  </div>
</template>

<script setup>
import { inject, reactive, computed, nextTick, watch } from "vue"
import { useMutation, useQuery } from "@vue/apollo-composable"
import TEAMS_QUERY from "@/constants/graphql/queries/teams.gql"
import EDITING_TEAM_QUERY from "@/constants/graphql/queries/team-data-for-edit-query.gql"
import EDIT_TEAM_MUTATION from "@/constants/graphql/mutations/team-editing-mutation.gql"
import { deepClone } from "@/utils/helpers"
import { SNACKBAR_MESSAGE_TYPES } from "@/constants"
import DatePicker from "@/components/BaseDatePicker"
import { useVuelidate } from "@vuelidate/core"
import { required, helpers } from "@vuelidate/validators"
import { Validations } from "@/utils/validation/validations"
import { startDateBeforeEndDate } from "@/utils/validation/validationHelpers"

const store = inject("store")
const props = defineProps({ payload: Object })
const emit = defineEmits(["loading"])
const editedTeam = reactive({
  name: "",
  start_date: "",
  end_date: null,
  total_sale_goal_nok: 0
})

const rules = computed(() => {
  return {
    name: {
      required: helpers.withMessage(Validations.required, required)
    },
    start_date: {
      required: helpers.withMessage(Validations.required, required),
      maxValue: helpers.withMessage(
        Validations.startDate,
        () => !editedTeam.end_date || startDateBeforeEndDate(editedTeam.start_date, editedTeam.end_date)
      )
    },
    end_date: {
      required: helpers.withMessage(Validations.required, required)
    },
    total_sale_goal_nok: {
      required: helpers.withMessage(Validations.required, required)
    }
  }
})

const v$ = useVuelidate(rules, editedTeam)

const { onResult: onEditingTeamData, loading: loadingTeam } = useQuery(EDITING_TEAM_QUERY, { teamID: props.payload.id })

onEditingTeamData(({ data: { getTeamDataForEdit } }) => {
  // UpdateTeamInput
  editedTeam.id = getTeamDataForEdit.id
  editedTeam.event_id = getTeamDataForEdit.event_id
  editedTeam.name = getTeamDataForEdit.name
  editedTeam.start_date = getTeamDataForEdit.start_date
  editedTeam.end_date = getTeamDataForEdit.end_date
  editedTeam.total_sale_goal_nok = getTeamDataForEdit.total_sale_goal_nok
})

const onUpdateTeam = async () => {
  const isInputCorrect = await v$.value.$validate()
  if (!isInputCorrect) return

  await updateTeam()
}

const { mutate: updateTeam, onDone } = useMutation(EDIT_TEAM_MUTATION, () => ({
  variables: { input: editedTeam },
  update: (cache, { data: { updateTeam } }) => {
    const { teams } = cache.readQuery({ query: TEAMS_QUERY })
    const itemIndex = teams.data.findIndex((team) => team.id === updateTeam.id)
    const updatedTeams = deepClone(teams)
    updatedTeams.data[itemIndex] = updateTeam
    cache.writeQuery({
      query: TEAMS_QUERY,
      data: {
        teams: updatedTeams
      }
    })
  },
  throws: "never"
}))

onDone(() => {
  nextTick(() => {
    closeModal()
    store.ui.methods.setSnackbarMessage({
      message: "Successfully updated team",
      type: SNACKBAR_MESSAGE_TYPES.SUCCESS
    })
  })
})
const closeModal = () => {
  store.ui.methods.setModalWindowState()
}

watch(
  () => [loadingTeam.value],
  () => {
    emit("loading", loadingTeam.value)
  },
  { immediate: true }
)
</script>
