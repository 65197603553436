export default {
  actual_length: {
    prettyName: "Actual Length"
  },
  actual_total_sqm: {
    prettyName: "Actual Total Square Meters"
  },
  actual_width: {
    prettyName: "Actual Width"
  },
  additional_info: {
    prettyName: "Additional Information"
  },
  allows_meeting_requests: {
    prettyName: "Allow Meeting Requests"
  },
  area_pricing_group_id: {
    prettyName: "Area Pricing Group (ID)"
  },
  attachments: {
    prettyName: "Attachments"
  },
  company_url_deprecated: {
    deprecated: true
  },
  created_at: {
    prettyName: "Created Date"
  },
  deal_id: {
    ignore: true
  },
  deleted_at: {
    prettyName: "Deleted Date"
  },
  description: {
    ignore: true
  },
  description_en: {
    prettyName: "Description (en)"
  },
  description_no: {
    prettyName: "Description (no)"
  },
  discount: {
    prettyName: "Discount"
  },
  discount_type: {
    prettyName: "Discount Type"
  },
  en: {
    ignore: true
  },
  event_id: {
    ignore: true
  },
  exhibitor_id: {
    ignore: true
  },
  exhibitor_pass_limit: {
    prettyName: "Exhibitor Pass Limit"
  },
  external_stand_url: {
    prettyName: "External Stand URL"
  },
  fire_regulations_confirmed: {
    prettyName: "Fire Regulations Confirmed"
  },
  hallmap_all_url: {
    prettyName: "URL of Hallmap (All)"
  },
  hallmap_stand_url: {
    prettyName: "URL of Hallmap (Stand)"
  },
  historical_invoiced_total_sqm: {
    prettyName: "Historical Invoiced Total Sqm"
  },
  historical_price_registration_sum: {
    prettyName: "Historical price (stand + reg)"
  },
  historical_total_sqm: {
    prettyName: "Historical Total Sqm"
  },
  id: {
    ignore: true
  },
  invoiced_total_sqm: {
    prettyName: "Invoiced Total Sqm"
  },
  invoicing_reg_fee: {
    ignore: true
  },
  is_draft: {
    ignore: true
  },
  is_invoiced_total_sqm_updated: {
    ignore: true
  },
  is_new_exhibitor_deprecated: {
    deprecated: true
  },
  length: {
    prettyName: "Length"
  },
  location_id: {
    prettyName: "Location ID"
  },
  logo_print_url_deprecated: {
    deprecated: true
  },
  logo_url: {
    prettyName: "Logo URL"
  },
  meeting_request_emails: {
    ignore: true
  },
  offer_price_currency_deprecated: {
    deprecated: true
  },
  offer_price_deprecated: {
    deprecated: true
  },
  parent_id: {
    prettyName: "Parent Exhibitor (ID)"
  },
  pavilion_id: {
    prettyName: "Pavillion (ID)"
  },
  po_res_fee: {
    prettyName: "PO-number (reg.fee)"
  },
  po_stand_space: {
    prettyName: "PO-number (Stand)"
  },
  po_technical: {
    prettyName: "PO-number (Technical)"
  },
  price_nok: {
    prettyName: "Stand Fee"
  },
  price_registration_sum: {
    prettyName: "Price (Stand + Reg.fee)"
  },
  reg_fee_invoice_date: {
    prettyName: "Invoice Date (Reg.fee)"
  },
  registration_fee_nok: {
    prettyName: "Reg.fee"
  },
  registration_fee_payer: {
    prettyName: "Who pays reg.fee"
  },
  registration_fee_type: {
    prettyName: "Reg.fee type"
  },
  requested_length: {
    prettyName: "Requested Length"
  },
  requested_width: {
    prettyName: "Requested Width"
  },
  settings: {
    prettyName: "Settings"
  },
  show_exhibitor_name: {
    prettyName: "Show Exhibitor Name"
  },
  slug: {
    ignore: true
  },
  stand_detailed_url: {
    prettyName: "URL of Stand (detailed)"
  },
  stand_fee_invoice_date: {
    prettyName: "Invoice Date (Stand fee)"
  },
  stand_url: {
    prettyName: "URL of Stand"
  },
  standname: {
    ignore: true
  },
  standname_en: {
    prettyName: "Standname (en)"
  },
  standname_no: {
    prettyName: "Standname (no)"
  },
  standnumber: {
    prettyName: "Standnumber"
  },
  status: {
    prettyName: "Status"
  },
  total_sqm: {
    prettyName: "Total sqm"
  },
  type: {
    prettyName: "Type"
  },
  updated_at: {
    prettyName: "Updated Date"
  },
  width: {
    prettyName: "Width"
  }
}
