export default {
  accept_percentage: {
    deprecated: true
  },
  actual_length_deprecated: {
    deprecated: true
  },
  actual_total_sqm_deprecated: {
    deprecated: true
  },
  actual_width_deprecated: {
    deprecated: true
  },
  additional_info_deprecated: {
    deprecated: true
  },
  aggregated: {
    deprecated: true
  },
  area_pricing_group_id_deprecated: {
    deprecated: true
  },
  attachments_deprecated: {
    deprecated: true
  },
  calculated_status: {
    prettyName: "Status"
  },
  cancel_message: {
    prettyName: "Cancelled Reason"
  },
  canceled_at: {
    prettyName: "Cancelled Date"
  },
  comment: {
    deprecated: true
  },
  company_identificator_deprecated: {
    deprecated: true
  },
  completeness_percentage: {
    deprecated: true
  },
  count_sale_activities: {
    prettyName: "Number of Sale Activities on Deal"
  },
  created_at: {
    prettyName: "Created Date"
  },
  declined_at: {
    prettyName: "Declined Date"
  },
  deleted_at: {
    prettyName: "Deleted Date"
  },
  deleted_by_user_at: {
    prettyName: "Deleted Date"
  },
  deleted_by_user_id: {
    prettyName: "Deleted by User ID"
  },
  discount_deprecated: {
    deprecated: true
  },
  discount_type_deprecated: {
    deprecated: true
  },
  due_date: {
    deprecated: true
  },
  event: {
    ignore: true
  },
  event_id: {
    ignore: true
  },
  exhibitor_id: {
    prettyName: "Exhibitor ID"
  },
  follow_up_date: {
    deprecated: true
  },
  follow_up_status: {
    deprecated: true
  },
  historical_price_registration_sum_deprecated: {
    deprecated: true
  },
  historical_total_sqm_deprecated: {
    deprecated: true
  },
  id: {
    ignore: true
  },
  invoice_address_same: {
    deprecated: true
  },
  invoiced_total_sqm_deprecated: {
    deprecated: true
  },
  is_child: {
    ignore: true
  },
  is_invoiced_total_sqm_updated_deprecated: {
    deprecated: true
  },
  is_new: {
    deprecated: true
  },
  is_new_customer: {
    deprecated: true
  },
  is_sold_deprecated: {
    deprecated: true
  },
  last_done_sale_activity_id: {
    prettyName: "ID of last Done Sale Activity"
  },
  length_deprecated: {
    deprecated: true
  },
  next_upcoming_sale_activity_id: {
    prettyName: "ID of next upcoming Sale Activity"
  },
  offer_valid_to: {
    prettyName: "Offer Expiry Date"
  },
  offer_value_nok: {
    prettyName: "Offer Value"
  },
  po_res_fee_deprecated: {
    deprecated: true
  },
  po_stand_space_deprecated: {
    deprecated: true
  },
  po_technical_deprecated: {
    deprecated: true
  },
  position: {
    deprecated: true
  },
  price_nok_deprecated: {
    deprecated: true
  },
  price_registration_sum_deprecated: {
    deprecated: true
  },
  reg_fee_invoice_date_deprecated: {
    deprecated: true
  },
  registration_fee_nok_deprecated: {
    deprecated: true
  },
  registration_fee_type_deprecated: {
    deprecated: true
  },
  requested_length_deprecated: {
    deprecated: true
  },
  requested_width_deprecated: {
    deprecated: true
  },
  sale_activities: {
    ignore: true
  },
  segment: {
    deprecated: true
  },
  segment_calculated: {
    prettyName: "Segment (Calculated)"
  },
  segment_overwritten: {
    prettyName: "Segment (Override)"
  },
  segment_resulted: {
    prettyName: "Segment (Result)"
  },
  signup_comment: {
    prettyName: "Signup Comment"
  },
  sold_at: {
    prettyName: "Sold Date"
  },
  sold_by_user_id: {
    prettyName: "Sold by User ID"
  },
  source: {
    prettyName: "Source"
  },
  source_deal_id: {
    prettyName: "Source Deal ID"
  },
  stage: {
    prettyName: "Stage"
  },
  stand_fee_invoice_date_deprecated: {
    deprecated: true
  },
  stand_id_deprecated: {
    deprecated: true
  },
  stand: {
    ignore: true
  },
  stands: {
    ignore: true
  },
  stand_url_deprecated: {
    deprecated: true
  },
  status_deprecated: {
    deprecated: true
  },
  title: {
    deprecated: true
  },
  total_sqm_deprecated: {
    deprecated: true
  },
  updated_at: {
    prettyName: "Updated Date"
  },
  user_id: {
    prettyName: "Responsible User ID"
  },
  value_nok: {
    prettyName: "Potential Value"
  },
  visma_id_deprecated: {
    deprecated: true
  },
  width_deprecated: {
    deprecated: true
  }
}
