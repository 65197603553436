import { createRouter, createWebHistory } from "vue-router"
import store from "@/store"
import DashboardRoute from "@/containers/Dashboard/dashboard-route"
import ProjectRoute from "@/containers/Project/project-route"
import CustomersRoute from "@/containers/Customers/customers-route"
import SettingsRoute from "@/containers/Settings/settings-route"
import LoginRoute from "@/containers/Login/login-route"
import NotFoundRoute from "@/containers/NotFound/not-found-route"
import NoProjectSelected from "@/containers/NoProjectSelected/no-project-selected-route"
import NoAccess from "@/containers/NoAccess/no-access-route"
import retrieveUserInfo from "./middleware/retrieveUserInfo"

const routes = [
  DashboardRoute,
  ProjectRoute,
  NoProjectSelected,
  CustomersRoute,
  SettingsRoute,
  LoginRoute,
  NoAccess,
  {
    path: "/",
    redirect: { name: "dashboard" }
  },
  NotFoundRoute
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// exported in order to test in unit tests
export const beforeEachImpl = async (to, from, next) => {
  const hasToken = Boolean(localStorage.getItem("access-token"))
  // Redirect to Dashboard if user try accessing login having token
  if (hasToken === true && to.name === "login") {
    return next({ name: "dashboard" })
  } else if (hasToken === false && to.name !== "login") {
    // If no token provided then redirect to login
    return next({ name: "login" })
  } else if (to.name !== "login") {
    if (store.user.state.user === null) {
      await retrieveUserInfo()
    }
  }
  next()
}

router.beforeEach(async (to, from, next) => {
  await beforeEachImpl(to, from, next)
})

export default router
