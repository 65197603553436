import NoProjectSelected from "./index"
const Sidebar = () => import(/* webpackChunkName: "Sidebar" */ "@/components/TheSidebar")
const Header = () => import(/* webpackChunkName: "Header" */ "@/components/TheHeader")

export default {
  path: "/no-project",
  name: "no-project",
  components: {
    default: NoProjectSelected,
    sidebar: Sidebar,
    header: Header
  },
  meta: {
    requiredHeaderDynamicComponents: ["HistoricProjectsCheckbox", "ProjectsDropdown", "ProjectsDropdownOptions"]
  }
}
