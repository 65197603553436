export default {
  CONFIRM_DELETING_MODAL: "ConfirmDeletingModal",
  TEAM_CREATION_MODAL: "TeamCreationModal",
  TEAM_EDITING_MODAL: "TeamEditingModal",
  MEMBER_CREATION_MODAL: "MemberCreationModal",
  MEMBER_EDITING_MODAL: "MemberEditingModal",
  ACTIVITY_CREATION_MODAL: "ActivityCreationModal",
  ACTIVITY_EDITING_MODAL: "ActivityEditingModal",
  CONTACT_PERSONS_EDITING: "DealContactPersonsEditing",
  CUSTOMER_CARD_COMPANY_EDITING_MODAL: "CustomerCardCompanyEditing",
  CUSTOMER_CARD_CONTACT_EDITING_MODAL: "CustomerCardContactEditing",
  CUSTOMER_COMMENT_MODAL: "CustomerComment",
  DEAL_CREATION_MODAL: "dealCreation",
  DEALS_COMMENTS_MODAL: "DealsComments",
  DEAL_UNSELL_MODAL: "DealUnsell",
  CUSTOMER_SEARCH_MODAL: "CustomerSearching",
  CUSTOMER_SELECT_MODAL: "CustomerSelecting",
  CUSTOMER_CREATION_MODAL: "CustomerCreation",
  CUSTOMERS_MERGING_MODAL: "CustomersMerging",
  CONFIRM_DECLINE_CANCEL_DEAL_MODAL: "ConfirmDeclineCancelDeal",
  WARNING_MESSAGE_MODAL: "WarningMessage",
  EXTRA_ORDER_CREATING_EDITING_MODAL: "ExtraOrderCreatingEditing",
  PROJECT_INVOICING_MODAL: "ProjectInvoicing",
  TAG_CREATION_EDITING: "TagCreationEditing",
  DEAL_RESPONSIBLE_MODAL: "DealResponsible",
  ACTIVITY_FOLLOW_UP_CREATION: "ActivityFollowUpCreation"
}
