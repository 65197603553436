<template>
  <div class="flex flex-col">
    <div class="flex justify-center items-center mb-3">
      <select
        v-model="selectedPreviousContact"
        :disabled="!props.payload.previousContacts?.length"
        class="input h-fit w-2/5"
        data-test="previous-contacts"
        @change="setAsContact"
      >
        <option value="" disabled selected hidden>
          {{ !props.payload.previousContacts?.length ? "No previous contacts" : "Select previous contact" }}
        </option>
        <option v-for="contact in props.payload.previousContacts" :key="contact.email" :value="contact">
          {{ contact.firstname }} {{ contact.lastname }}
        </option>
      </select>

      <span class="mx-3">- or -</span>

      <div class="flex-col w-2/5">
        <button class="button w-full" @click="addNewContact" data-test="add-contact">
          <svg-icon classNames="text-white" name="plus" disabled></svg-icon>
          <span class="whitespace-nowrap">Add new contact</span>
        </button>
      </div>
    </div>

    <div class="contents">
      <div class="flex-col max-h-96 overflow-y-auto mt-4">
        <div
          v-for="(contact, index) in contacts"
          :key="index"
          class="border rounded p-6 mb-3"
          :data-test="'contact-' + index"
        >
          <div class="flex-col items-center">
            <div class="flex justify-between mt-2">
              <div class="flex-col w-1/2">
                <label class="text-gray-500">Email</label>
                <input
                  v-model="contact.email"
                  v-select-text
                  type="email"
                  class="input"
                  :class="{ 'input--invalid': v$.$each.$response.$errors[index].email.length }"
                  data-test="email"
                  @input="debouncedSearchByEmail(contact.email, index)"
                />
                <p
                  v-if="v$.$each.$response.$errors[index].email.length"
                  class="text-error"
                  data-test="email-validation"
                >
                  {{ v$.$each.$response.$errors[index].email[0].$message }}
                </p>
              </div>

              <div class="flex-col ml-3 w-1/2">
                <label class="text-gray-500">Phone</label>
                <div class="flex flex-row">
                  <select
                    v-model="contact.validatedPhoneNumber.countryCode"
                    class="input h-fit w-1/3"
                    @change="() => validateContactPhone(contact)"
                  >
                    <option v-for="countryCode in countryCodes" :key="countryCode" :value="countryCode">
                      {{ countryCode }}
                    </option>
                  </select>
                  <input
                    v-model="contact.validatedPhoneNumber.number"
                    v-select-text
                    class="input w-2/3 ml-2"
                    type="tel"
                    oninput="value = value.replace(/[^0-9+\s()-]/g, '')"
                    @focus="() => showUnformattedPhoneNumber(contact)"
                    @blur="() => validateContactPhone(contact)"
                    data-test="phone"
                  />
                </div>
                <p
                  v-if="contact.phone && !contact.validatedPhoneNumber.isValid"
                  class="text-error"
                  data-test="phone-validation"
                >
                  Not a valid phonenumber
                </p>
              </div>
            </div>

            <div class="flex justify-between mt-2">
              <div class="flex-col w-1/2">
                <label class="text-gray-500">First Name</label>
                <input
                  v-model="contact.firstname"
                  v-select-text
                  class="input"
                  :class="{ 'input--invalid': v$.$each.$response.$errors[index].firstname.length }"
                  data-test="firstname"
                />
                <p
                  v-if="v$.$each.$response.$errors[index].firstname.length"
                  class="text-error"
                  data-test="firstname-validation"
                >
                  {{ v$.$each.$response.$errors[index].firstname[0].$message }}
                </p>
              </div>

              <div class="flex-col ml-3 w-1/2">
                <label class="text-gray-500">Last Name</label>
                <input
                  v-model="contact.lastname"
                  v-select-text
                  class="input"
                  :class="{ 'input--invalid': v$.$each.$response.$errors[index].lastname.length }"
                  data-test="lastname"
                />
                <p
                  v-if="v$.$each.$response.$errors[index].lastname.length"
                  class="text-error"
                  data-test="lastname-validation"
                >
                  {{ v$.$each.$response.$errors[index].lastname[0].$message }}
                </p>
              </div>
            </div>

            <div class="flex-col mt-2">
              <label class="text-gray-500">Title</label>
              <input v-model="contact.title" v-select-text class="input" data-test="title" />
            </div>

            <div class="flex mt-3 justify-between">
              <div class="flex items-center">
                <div class="flex items-center w-max space-x-2 mr-3">
                  <input
                    :id="'role-' + index"
                    class="radio h-5 w-5"
                    type="radio"
                    v-model="contact.role"
                    :value="'PRIMARY'"
                    @click="setPrimaryRole(index)"
                    data-test="primary-role"
                  />
                  <label :for="'role-' + index" class="text-normal cursor-pointer">Primary contact</label>
                </div>

                <div class="flex items-center w-max space-x-2">
                  <input
                    v-model="contact.is_mystand_admin"
                    :id="'admin-' + index"
                    :disabled="contact.role === 'PRIMARY'"
                    class="checkbox h-5 w-5"
                    type="checkbox"
                    data-test="mystand"
                  />
                  <label :for="'admin-' + index" class="text-normal cursor-pointer">Stand admin</label>
                </div>
              </div>

              <button
                class="button h-8"
                :disabled="contact.role === 'PRIMARY'"
                :title="contact.role === 'PRIMARY' ? 'You can\'t delete the primary contact' : ''"
                @click="deleteContact(index)"
              >
                <span class="whitespace-nowrap">Delete Contact</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CustomerCreation"
}
</script>

<script setup>
import { computed, reactive, ref, watch, onMounted, inject } from "vue"
import { email, helpers, required, requiredIf } from "@vuelidate/validators"
import { useVuelidate } from "@vuelidate/core"
import { debounce, deepClone, areEquivalent } from "@/utils/helpers"
import { useQuery } from "@vue/apollo-composable"
import DEAL_CONTACTS_BY_EMAIL from "@/constants/graphql/queries/deal-contacts-by-email.gql"
import { validateMobileNumber, getCountryCodes } from "@/utils/phoneNumberUtils"

const emit = defineEmits(["loading"])
const props = defineProps({
  payload: {
    type: Object,
    required: false,
    default: () => ({
      contacts: [],
      previousContacts: [],
      isSoldDeal: false
    })
  }
})
const store = inject("store")

const contacts = ref([])
const selectedPreviousContact = ref("")
const countryCodes = ref([])

onMounted(async () => {
  contacts.value = await getValidatedContacts()
  if (contacts.value?.length && !contacts.value.some((contact) => contact.role === "PRIMARY")) {
    if (contacts.value[0]) {
      contacts.value[0].role = "PRIMARY"
      contacts.value[0].is_mystand_admin = true
    }
  }

  countryCodes.value = await getCountryCodes()
})

const showUnformattedPhoneNumber = (contact) => {
  if (!contact.validatedPhoneNumber?.isValid && !contact.validatedPhoneNumber?.countryCode) {
    // not a valid phonenumber, so just show what we have stored in db
    contact.validatedPhoneNumber.number = contact.phone
  }
}

const validateContactPhone = async (contact) => {
  const phoneNumber = (contact.validatedPhoneNumber.countryCode + contact.validatedPhoneNumber.number).replace(
    /\s/g,
    ""
  )
  contact.phone = phoneNumber
  const res = await validateMobileNumber(phoneNumber)
  if (res) {
    contact.validatedPhoneNumber = res
  } else {
    contact.validatedPhoneNumber.isValid = false
  }
}

const getValidatedContacts = async () => {
  const validatedContacts = deepClone(props.payload.contacts) ?? []
  for (const contact of validatedContacts) {
    if (contact.phone) {
      const res = await validateMobileNumber(contact.phone)
      if (res) {
        contact.validatedPhoneNumber = res
      } else {
        contact.validatedPhoneNumber = {
          number: contact.phone,
          countryCode: ""
        }
      }
    } else {
      // Handle user that has no phone number
      contact.validatedPhoneNumber = {
        number: "",
        countryCode: ""
      }
    }
  }
  return validatedContacts
}

const variablesForQuery = reactive({
  email: "",
  index: null
})
const emailForQuery = computed(() => variablesForQuery.email)
const enabledDealContactsQuery = ref(false)

const {
  onResult,
  onError,
  loading: loadingContacts
} = useQuery(DEAL_CONTACTS_BY_EMAIL, { email: emailForQuery }, () => ({
  enabled: enabledDealContactsQuery.value,
  fetchPolicy: "no-cache"
}))
onResult(async ({ data: { dealContactsByEmail } }) => {
  enabledDealContactsQuery.value = false
  if (dealContactsByEmail?.length) {
    const contact = deepClone(dealContactsByEmail[0])
    contact.validatedPhoneNumber = {
      countryCode: "",
      number: contact.phone
    }

    store.ui.methods.setExceptionalLoading(true)
    await validateContactPhone(contact)
    store.ui.methods.setExceptionalLoading(false)
    contacts.value[variablesForQuery.index] = contact
    if (contacts.value.length === 1) {
      contacts.value[variablesForQuery.index].role = "PRIMARY"
      contacts.value[variablesForQuery.index].is_mystand_admin = true
    }
  }
})
onError(() => {
  enabledDealContactsQuery.value = false
})

const isUnique = (value) => {
  if (!value) return true
  return contacts.value.filter((contact) => contact.email === value).length <= 1
}

const rules = {
  $each: helpers.forEach({
    firstname: {
      required
    },
    lastname: {
      required
    },
    email: {
      requiredIfRef: requiredIf(props.payload.isSoldDeal),
      email,
      isUnique: helpers.withMessage("The email must not be repeated", isUnique)
    }
  })
}
const v$ = useVuelidate(rules, contacts)

const setPrimaryRole = (index) => {
  contacts.value = contacts.value.map((contact, i) => {
    if (index === i) {
      contact.is_mystand_admin = true
    } else {
      contact.role = null
    }

    return contact
  })
}

const addNewContact = () => {
  const isPresentPrimaryContact = contacts.value.some((contact) => contact.role === "PRIMARY")

  contacts.value.push({
    firstname: null,
    lastname: null,
    phone: null,
    validatedPhoneNumber: {
      countryCode: "",
      number: ""
    },
    email: null,
    role: isPresentPrimaryContact ? null : "PRIMARY",
    is_mystand_admin: !isPresentPrimaryContact
  })
}

const setAsContact = async () => {
  const selectedContact = deepClone(selectedPreviousContact.value)

  // Validate phonenumber for selected previous contact
  selectedContact.validatedPhoneNumber = {
    countryCode: "",
    number: selectedContact.phone
  }
  store.ui.methods.setExceptionalLoading(true)
  await validateContactPhone(selectedContact)
  store.ui.methods.setExceptionalLoading(false)

  if (!contacts.value.some((contact) => contact.role === "PRIMARY")) {
    selectedContact.role = "PRIMARY"
    selectedContact.is_mystand_admin = true
  }

  contacts.value.push(selectedContact)

  selectedPreviousContact.value = ""

  await v$.value.$validate()
}

const deleteContact = (index) => {
  contacts.value.splice(index, 1)
}

const isValid = computed(() => v$.value.$validate())

const debouncedSearchByEmail = debounce((email, index) => {
  v$.value.$touch()
  if (!v$.value.$each.$response.$errors[index].email.length) {
    variablesForQuery.email = email
    variablesForQuery.index = index
    if (email) {
      enabledDealContactsQuery.value = true
    }
  }
}, 300)

const haveContactsChanged = () => {
  // Remove validation object from contacts before comparing with payload
  const contactsWithoutValidationObject = deepClone(contacts.value).map((contact) => {
    delete contact.validatedPhoneNumber
    return contact
  })
  return !areEquivalent(contactsWithoutValidationObject, props.payload.contacts)
}

watch(
  () => props.payload.contacts,
  (newValue) => {
    if (newValue.length) {
      contacts.value = newValue
      contacts.value[0].role = "PRIMARY"
      contacts.value[0].is_mystand_admin = true
    }
  }
)

watch(
  () => [loadingContacts.value],
  () => {
    emit("loading", loadingContacts.value)
  },
  { immediate: true }
)

defineExpose({
  isValid,
  contacts,
  areAnyChanges: computed(() => !enabledDealContactsQuery.value && haveContactsChanged())
})
</script>
