<template>
  <MultiStepsProvider
    ref="multiStepsProviderRef"
    :stages="stages"
    :entrypointComponent="entrypointComponent"
    :loading="showLoading"
  >
    <template #title>
      <slot name="title"></slot>
    </template>
  </MultiStepsProvider>
</template>

<!-- export name for cache (<keep-alive />) -->
<script>
export default {
  name: "DealCreation"
}
</script>

<script setup>
import { watchEffect, reactive, ref, shallowRef, computed, inject, nextTick, onUnmounted } from "vue"
import { useMutation, useQuery } from "@vue/apollo-composable"
import { useRoute } from "vue-router"
import {
  createCustomer,
  onCustomerCreated,
  onCustomerError
} from "@/components/TheModalWindow/CustomerCreation/CustomerCreation"
import { isObjectEmpty, combineDateAndTime, longDateFormat, deepClone } from "@/utils/helpers"
import { useCountryService, findCustomerCountry } from "@/utils/countryService"
import DEAL_CREATION_MODAL from "./dealCreationModalNames"

import EXISTING_CUSTOMERS_QUERY from "./existing-customers.gql"
import EXISTING_CUSTOMER_DEALS from "./existing-customer-deals.gql"
import PRESELECTED_CUSTOMER_INFO_QUERY from "./preselected-customer-query.gql"
import PREVIOUS_DEAL_CONTACTS from "@/containers/Deal/General/ContactPersons/previous-deal-contacts-query.gql"
import MEMBERS_QUERY from "@/constants/graphql/queries/team-users-by-event-id.gql"
import TAGS_QUERY from "./tags-by-event-id.gql"
import DEAL_CREATION_MUTATION from "./deal-creation-mutation.gql"
import ACTIVITY_CREATION_MUTATION from "@/components/TheModalWindow/ActivityCreation/activity-creation-mutation.gql"
import SIGNUP_ACTIVITY_CREATION_MUTATION from "@/constants/graphql/mutations/create-signup-activity-mutation.gql"
import DEAL_CONTACTS_MUTATION from "@/constants/graphql/mutations/deal-contacts-mutation.gql"
import UPDATE_TAGS_MUTATION from "@/constants/graphql/mutations/update-tags-mutation.gql"

import MultiStepsProvider from "../BaseMultiStepsModalProvider"
import EventSelection from "./EventSelection"
import CustomerSearching from "@/components/BaseCustomer/BaseCustomerSearching"
import CustomerCreation from "@/components/BaseCustomer/BaseCustomerCreation"
import DealContactPersons from "@/components/BaseCustomer/BaseDealContactPersons"
import ExistingCustomerDeal from "@/components/BaseCustomer/ExistingCustomerDeal"
import DealDetails from "./DealDetails"
import { SNACKBAR_MESSAGE_TYPES } from "@/constants"
import EDIT_CUSTOMER from "@/constants/graphql/mutations/customer-updating-mutation.gql"
import CUSTOMER_QUERY from "@/constants/graphql/queries/customer.gql"
import DEAL_CONTACTS from "@/constants/graphql/queries/deal-contacts.gql"
import DEAL_MUTATION from "@/containers/Deal/General/deal-mutation.gql"
import EDIT_STAND from "@/constants/graphql/mutations/stand-mutation.gql"
import STAND_PRICE from "@/constants/graphql/queries/stand-price.gql"

const store = inject("store")
const route = useRoute()

const props = defineProps({
  payload: {
    type: Object,
    required: true,
    default: () => ({
      events: {},
      preselectedEventId: null,
      preselectedCustomerId: null,
      importedCustomer: {},
      importedInvoiceCompany: {},
      importedContact: {},
      teamId: null,
      importedComment: "",
      fromExhibitorSignups: false,
      importedTags: [],
      insertDeal: () => {},
      insertMainContact: () => {},
      insertNextUpcomingSaleActivity: () => {},
      onDealCreatedCallback: () => {}
    })
  }
})

const impersonateUserId = store.ui.state.currentDashboardUser?.id
const isDashboard = route.name === "dashboard-deals-table"
const isAdmin = computed(() => store.user.getters.isGlobalAdmin())
const userId = store.user.state.user.id
const multiStepsProviderRef = ref()
const { countries } = useCountryService()

const customersWhereCondition = computed(() => {
  const where = {
    OR: [
      {
        column: "COMPANY_NAME",
        operator: "ILIKE",
        value: props.payload.importedCustomer?.companyName
      }
    ]
  }

  if (props.payload.importedCustomer?.companyIdentificator) {
    where.OR.push({
      column: "COMPANY_IDENTIFICATOR",
      operator: "EQ",
      value: props.payload.importedCustomer?.companyIdentificator
    })
  }

  return where
})
const matchingCustomers = ref([])
const selectedMatchingCustomer = ref({})
const { onResult: onCustomerSearchResult, loading: loadingCustomers } = useQuery(
  EXISTING_CUSTOMERS_QUERY,
  {
    where: customersWhereCondition.value
  },
  () => ({
    enabled: !isObjectEmpty(props.payload.importedCustomer),
    fetchPolicy: "no-cache"
  })
)
onCustomerSearchResult(
  ({
    data: {
      customers: { data: matchedCustomers }
    }
  }) => {
    if (matchedCustomers?.length) {
      matchingCustomers.value = matchedCustomers
    }
  }
)

const existingCustomersDeals = ref([])
const existMatchingCustomers = computed(() => Boolean(matchingCustomers.value.length))

const existingCustomerDealVariables = computed(() => ({
  teamId: props.payload.teamId,
  where: {
    OR: matchingCustomers.value.map((matchingCustomer) => {
      return {
        column: "EXHIBITOR_ID",
        value: matchingCustomer.id,
        operator: "EQ"
      }
    })
  },
  includeCoExhibitors: true
}))

const { onResult: onExistingCustomersDealsResult, loading: loadingExistingDeals } = useQuery(
  EXISTING_CUSTOMER_DEALS,
  existingCustomerDealVariables,
  () => ({ enabled: existMatchingCustomers, fetchPolicy: "no-cache" })
)
onExistingCustomersDealsResult(
  ({
    data: {
      dealsByTeamId: { data: dealsResult }
    }
  }) => {
    existingCustomersDeals.value = dealsResult?.filter((deal) => !deal.isDeleted)
  }
)

const confirmationFromExistingCustomer = ref(false)
const showConfirmationFromExistingCustomer = ref(false)
const overwriteCustomer = ref(false)
const elementsToOverwrite = ref({})
const overwriteDeal = ref(false)
const standSizeOverwrite = ref(false)
const standnameOverwrite = ref(false)

const overwriteDealInformation = computed(() => {
  const deal = deepClone(existingCustomersDeals.value?.find((el) => el.id === overwriteDeal.value))
  if (deal && deal?.tags.length) {
    deal.tags = deal.tags?.map((tag) => ({ title: tag.fullName.no, value: tag.id }))
  }
  return deal
})

/* Creates a deal if customer is created */
const customerCreationWatcher = onCustomerCreated(({ data: { createCustomer } }) => {
  dealCreationData.customerId = createCustomer.id
  if (!overwriteDeal.value) {
    createDeal(getDealCreationInput())
  }
})

const customerErrorWatcher = onCustomerError((err) => {
  store.ui.methods.setSnackbarMessage({
    message: `Customer creation has failed. ${err}`,
    type: SNACKBAR_MESSAGE_TYPES.ERROR
  })
})

/* DEAL CREATION */
const dealCreationData = reactive({
  eventId: props.payload.preselectedEventId || null,
  customerId: props.payload.preselectedCustomerId,
  responsibleId: null,
  value_nok: 0,
  signupComment: ""
})
const createdDealId = ref(null) // Required for contacts and activity
const createdDealDate = ref()

const getDealCreationInput = () => {
  const inputData = {
    input: {
      event_id: dealCreationData.eventId,
      exhibitor_id: dealCreationData.customerId,
      user_id: dealCreationData.responsibleId,
      value_nok: dealCreationData.value_nok,
      signup_comment: dealCreationData.signupComment
    }
  }

  if (props.payload.fromExhibitorSignups && props.payload.importedCustomer.standSizeLength) {
    inputData.input.standsize_length = props.payload.importedCustomer.standSizeLength
  }

  if (props.payload.fromExhibitorSignups && props.payload.importedCustomer.standSizeWidth) {
    inputData.input.standsize_width = props.payload.importedCustomer.standSizeWidth
  }

  if (props.payload.fromExhibitorSignups && inputData.input.standsize_width && inputData.input.standsize_length) {
    const area = props.payload.importedCustomer.standSizeLength * props.payload.importedCustomer.standSizeWidth
    inputData.input.total_sqm = area
  }

  if (fromExhibitorSignups.value && props.payload.importedCustomer.standname) {
    inputData.input.standname = props.payload.importedCustomer.standname
  }

  if (fromExhibitorSignups.value) {
    inputData.input.source = "SIGNUP"
  }

  return inputData
}

const {
  mutate: createDeal,
  onDone: onDealCreated,
  onError: onDealError
} = useMutation(DEAL_CREATION_MUTATION, () => ({
  update: props.payload.insertDeal
}))

onDealCreated(({ data: { createDeal } }) => {
  createdDealId.value = createDeal.id
  createdDealDate.value = createDeal.createdAt
  props.payload.onDealCreatedCallback?.(createDeal.id)
  createDealDependenciesSync()
})

onDealError((err) => {
  store.ui.methods.setSnackbarMessage({
    message: `Deal creation has failed. ${err}`,
    type: SNACKBAR_MESSAGE_TYPES.ERROR
  })
})

const { mutate: updateDeal, onDone: onDealUpdated } = useMutation(DEAL_MUTATION, () => ({ throws: "never" }))
onDealUpdated(({ data: { updateDeal } }) => {
  createdDealId.value = updateDeal.id
  createdDealDate.value = updateDeal.createdAt
  props.payload.onDealCreatedCallback?.(updateDeal.id)
  createDealDependenciesSync()
})

const { mutate: updateCustomer } = useMutation(EDIT_CUSTOMER, () => ({
  update: (cache, { data: { updateCustomer: updatedCustomer } }) => {
    cache.writeQuery({
      query: CUSTOMER_QUERY,
      data: {
        customer: deepClone(updatedCustomer)
      },
      variables: {
        id: updatedCustomer.id
      }
    })
  },
  throws: "never"
}))

// Synchronously creates optional contacts, activity and tags when Deal is created
const createDealDependenciesSync = () => {
  const shouldCreateActivity = !isActivityCreated.value && !isObjectEmpty(activityCreationData.value)
  const shouldCreateContacts = !areContactsCreated.value && Boolean(contactsCreationData.value.length)
  const shouldUpdateTags = Boolean(dealTags.value?.length)

  // closes window if nothing in queue needs to be created
  if (!shouldCreateActivity && !shouldCreateContacts && !shouldUpdateTags) {
    closeModal()
    return
  }

  const result = []
  if (shouldCreateContacts) {
    result.push(
      updateContactPersons({
        dealId: overwriteDeal.value ? overwriteDeal.value : createdDealId.value,
        input: contactsCreationData.value
      })
    )
  }
  if (props.payload.fromExhibitorSignups) {
    result.push(
      createSignupActivity({
        input: {
          customer_id: dealCreationData.customerId,
          deal_id: createdDealId.value,
          user_id: userId,
          comment: dealCreationData.signupComment,
          date: longDateFormat(new Date(Date.now()))
        }
      })
    )
  }
  if (shouldCreateActivity) {
    result.push(createActivity(activityCreationInput({ ...activityCreationData.value })))
  }
  if (shouldUpdateTags) {
    result.push(
      updateDealTags({
        input: {
          id: overwriteDeal.value ? overwriteDeal.value : createdDealId.value,
          tags_id: dealTags.value?.map((el) => el.value)
        }
      })
    )
  }
  // closes modal if optional activity/contacts/tags are created without any error
  Promise.all(result).then((response) => {
    if (response.every((data) => data !== null)) closeModal()
  })
}

/* STAND UPDATING */
const { mutate: updateStand } = useMutation(EDIT_STAND, () => ({
  throws: "never"
}))

/* ACTIVITY CREATION */
const activityCreationData = ref({})
const isActivityCreated = ref(false)

const {
  mutate: createActivity,
  onDone: onActivityCreated,
  onError: onActivityError
} = useMutation(ACTIVITY_CREATION_MUTATION, () => ({ throws: "never" }))

onActivityCreated(({ data: { createSaleActivity: activity } }) => {
  isActivityCreated.value = true
  props.payload.insertNextUpcomingSaleActivity(createdDealId.value, activity)
})

onActivityError((err) => {
  store.ui.methods.setSnackbarMessage({
    message: `Activity creation has failed. ${err}`,
    type: SNACKBAR_MESSAGE_TYPES.ERROR
  })
})

const { mutate: createSignupActivity, onError: onActivitySignupError } = useMutation(
  SIGNUP_ACTIVITY_CREATION_MUTATION,
  () => ({ throws: "never" })
)

onActivitySignupError((err) => {
  store.ui.methods.setSnackbarMessage({
    message: `Activity creation has failed. ${err}`,
    type: SNACKBAR_MESSAGE_TYPES.ERROR
  })
})

const activityCreationInput = ({ responsibleId, date, startTime, endTime, typeId }) => ({
  input: {
    deal_id: createdDealId.value,
    user_id: responsibleId,
    date,
    start_time: startTime ? combineDateAndTime(date, startTime) : null,
    end_time: endTime ? combineDateAndTime(date, endTime) : null,
    sale_activity_type_id: typeId
  }
})

/* CUSTOMER DATA BY PRESELECTION */
const fromExhibitorSignups = computed(() => props.payload.fromExhibitorSignups)
const showEventSelection = computed(() => !!(props.payload.importedCustomer || !props.payload.preselectedEventId))
const isCustomerPreselected = computed(() => Boolean(props.payload.preselectedCustomerId))
const isCustomerImported = computed(() => !isObjectEmpty(props.payload.importedCustomer))
const isCustomerEditable = ref(isCustomerImported.value)
const customerCreationData = ref({})
const searchedCompanyName = ref("")

const { onResult: onPreselectedCustomerResult, loading: loadingPreSelCustomer } = useQuery(
  PRESELECTED_CUSTOMER_INFO_QUERY,
  { id: props.payload.preselectedCustomerId },
  () => ({ enabled: isCustomerPreselected })
)

onPreselectedCustomerResult(({ data: { customer } }) => {
  customerCreationData.value = customer
  dealCreationData.customerId = customer.id
})

const getDealComment = computed(() => {
  if (props.payload.fromExhibitorSignups) {
    let comment = props.payload.importedComment

    if (props.payload.importedInvoiceCompany?.invoiceName) {
      const invoice = {
        orgNo: props.payload.importedInvoiceCompany.invoiceIdentificator
          ? `, ${props.payload.importedInvoiceCompany.invoiceIdentificator}`
          : "",
        name: props.payload.importedInvoiceCompany.invoiceName,
        address: props.payload.importedInvoiceCompany.invoiceAddress,
        zip: props.payload.importedInvoiceCompany.invoiceZip,
        city: props.payload.importedInvoiceCompany.invoiceCity,
        country: findCustomerCountry(props.payload.importedInvoiceCompany.invoiceCountrycode, countries)?.name
      }

      comment += "\n\nThe exhibitor has requested the invoice to be addressed to the following company:"
      comment += `\n${invoice.name}${invoice.orgNo}\n${invoice.address}\n${invoice.zip} ${invoice.city}\n${invoice.country}`
      comment += "\nOpen the customer card to set the invoicing company"
    }

    return comment
  }

  return ""
})

/* CUSTOMER/DEAL CONTACTS */
const previousContacts = ref([])
const previousContactsVariables = computed(() => ({
  exhibitorId: props.payload.preselectedCustomerId || dealCreationData.customerId
}))

const { onResult } = useQuery(PREVIOUS_DEAL_CONTACTS, previousContactsVariables, () => ({
  enabled: Boolean(isCustomerPreselected.value || dealCreationData.customerId) // preselected or existing
}))
onResult(({ data: { dealContactsByExhibitorId: previousContactsResult } }) => {
  previousContacts.value = previousContactsResult?.map((el) => ({ ...el, role: null }))
})

/* DEAL CONTACTS */
const alreadyExistDealContacts = ref([])
const { onResult: onDealContactsResult, loading: loadingExistingDealContacts } = useQuery(
  DEAL_CONTACTS,
  { dealId: overwriteDeal },
  () => ({
    enabled: !!overwriteDeal.value
  })
)
onDealContactsResult(({ data: { dealContactsByDealId: dealContactsResult } }) => {
  if (dealContactsResult) {
    const deals = deepClone(dealContactsResult)
    alreadyExistDealContacts.value = deals?.map((el) => ({ ...el, role: null }))
  }
})

const contactsCreationData = ref([])
const areContactsCreated = ref(false)

const {
  mutate: updateContactPersons,
  onDone: onContactsCreation,
  onError: onContactsError
} = useMutation(DEAL_CONTACTS_MUTATION, () => ({
  update: (cache, { data: { syncDealContacts: contacts } }) => {
    const mainContact = contacts?.find((contact) => contact.role === "PRIMARY")
    if (!isObjectEmpty(mainContact)) {
      props.payload.insertMainContact(createdDealId.value, mainContact)
    }
  },
  throws: "never"
}))

onContactsCreation(() => {
  areContactsCreated.value = true
})

onContactsError((err) => {
  store.ui.methods.setSnackbarMessage({
    message: `Contacts creation has failed. ${err}`,
    type: SNACKBAR_MESSAGE_TYPES.ERROR
  })
})

/* TEAM MEMBERS */
const teamMembers = ref(null)
const eventId = computed(() => dealCreationData.eventId) // ref variable

const { onResult: onTeamMembersResult, loading: loadingMembers } = useQuery(MEMBERS_QUERY, { eventId }, () => ({
  enabled: Boolean(dealCreationData.eventId),
  fetchPolicy: "cache-first"
}))

onTeamMembersResult(({ data: { event } }) => {
  teamMembers.value = event.team.teamUsers.map((member) => {
    return {
      userId: member.userId,
      userName: member.user.fullName
    }
  })
})

const getCombinedTags = computed(() => {
  let combinedTags = [...props.payload.importedTags.map((tag) => ({ title: tag.title, value: tag.value }))]

  if (overwriteDeal.value) {
    combinedTags = [...combinedTags, ...(overwriteDealInformation.value?.tags || [])]
  }

  // Create a map to store unique tags based on id
  const uniqueTagsMap = new Map()

  // Iterate through the combined tags and add them to the map, overwriting duplicates
  combinedTags.forEach((tag) => {
    uniqueTagsMap.set(tag.value, tag)
  })

  // Convert the map values back to an array
  return Array.from(uniqueTagsMap.values())
})

/* TAGS */
const eventTags = ref([])

const { onResult: onTagsResult } = useQuery(TAGS_QUERY, { eventId }, () => ({
  enabled: Boolean(dealCreationData.eventId)
}))

onTagsResult(({ data: { event } }) => {
  eventTags.value = event.tags
    ?.map(({ fullName, settings, id }) => ({
      title: JSON.parse(settings)?.is_public ? `${fullName?.no || fullName?.en} (p)` : fullName?.no || fullName.en,
      value: id
    }))
    .sort((a, b) => a.title.toUpperCase()?.localeCompare(b.title.toUpperCase()))
})

/* TAGS UPDATING */
const dealTags = ref([])

const { mutate: updateDealTags } = useMutation(UPDATE_TAGS_MUTATION, () => ({
  throws: "never"
}))
/* --- --- */

/* STAGES CONFIGURATION */
const entrypointComponent = showEventSelection.value
  ? DEAL_CREATION_MODAL.EVENT_SELECTION_MODAL
  : DEAL_CREATION_MODAL.CUSTOMER_SEARCH_MODAL

const prevComponentFromDealContact = computed(() => {
  if (isCustomerPreselected.value) {
    return DEAL_CREATION_MODAL.EVENT_SELECTION_MODAL
  }
  if (existMatchingCustomers.value) {
    return DEAL_CREATION_MODAL.EXISTING_CUSTOMER_DEAL_MODAL
  }
  if (dealCreationData.customerId) {
    return DEAL_CREATION_MODAL.CUSTOMER_SEARCH_MODAL
  }
  return DEAL_CREATION_MODAL.CUSTOMER_CREATION_MODAL
})

const existingCustomers = computed(() => {
  if (!existingCustomersDeals.value.length) {
    // There are no deals for any of the matching customers
    // Just pick the first matching customer
    return existMatchingCustomers.value ? [matchingCustomers.value[0]] : []
  } else {
    // Retrieve all the customers of the existing deals
    const idOfExistingDealsCustomers = existingCustomersDeals.value.map((existingDeal) => existingDeal.exhibitor.id)
    return matchingCustomers.value.filter((matchingCustomer) =>
      idOfExistingDealsCustomers.some((id) => matchingCustomer.id === id)
    )
  }
})

const mergeContacts = (newContact, existingContacts) => {
  const index = existingContacts.findIndex((contact) => contact.email === newContact.email)
  if (index > -1) {
    // contact exists, only keep the newContact
    existingContacts.splice(index, 1)
  }
  return [newContact, ...existingContacts]
}

const stages = shallowRef()

const updateStagesData = () => {
  stages.value = {
    [DEAL_CREATION_MODAL.EVENT_SELECTION_MODAL]: {
      stage: 1,
      title: "event",
      component: EventSelection,
      next: DEAL_CREATION_MODAL.CUSTOMER_SEARCH_MODAL,
      previous: null,
      consentToTheNextPage: true,
      onNextPageClick: ({ eventId }) => {
        dealCreationData.eventId = eventId
      },
      isInvisible: !showEventSelection.value,
      payload: {
        events: props.payload.events,
        preselectedEventId: props.payload.preselectedEventId,
        isEventReadOnly: Boolean(props.payload.preselectedEventId)
      }
    },
    [DEAL_CREATION_MODAL.CUSTOMER_SEARCH_MODAL]: {
      stage: 2,
      title: "customer",
      component: CustomerSearching,
      next: isCustomerPreselected.value
        ? DEAL_CREATION_MODAL.DEAL_CONTACTS_MODAL
        : DEAL_CREATION_MODAL.EXISTING_CUSTOMER_DEAL_MODAL,
      previous: showEventSelection.value ? DEAL_CREATION_MODAL.EVENT_SELECTION_MODAL : null,
      excludeNextStageFromCache: true, // don't keep alive customer creation
      skip: isCustomerPreselected.value || isCustomerImported.value,
      isInvisible: isCustomerPreselected.value, // invisible means to not show step icon
      consentToTheNextPage: true,
      onNextPageClick: ({ customer, existingCustomerId }) => {
        if (existingCustomerId) {
          dealCreationData.customerId = existingCustomerId
        }
        if (customer) {
          customerCreationData.value = customer
        }
      },
      payload: {
        withSelection: true,
        eventId: dealCreationData.eventId,
        addClickHandler: (searchedCompany) => {
          dealCreationData.customerId = null
          searchedCompanyName.value = searchedCompany
          isCustomerEditable.value = true
          multiStepsProviderRef.value.toNextPage({ rawNavigation: true })
        }
      }
    },
    [DEAL_CREATION_MODAL.EXISTING_CUSTOMER_DEAL_MODAL]: {
      stage: 3,
      title: "customer",
      component: ExistingCustomerDeal,
      next:
        !isCustomerImported.value || !existMatchingCustomers.value
          ? DEAL_CREATION_MODAL.CUSTOMER_CREATION_MODAL
          : DEAL_CREATION_MODAL.DEAL_CONTACTS_MODAL,
      previous: DEAL_CREATION_MODAL.EVENT_SELECTION_MODAL,
      skip: !isCustomerImported.value || !existMatchingCustomers.value, // skip if there are no matching customers
      isInvisible: true, // customer creation step is always treat as part of searching
      excludeNextStageFromCache: true, // don't keep alive customer creation
      consentToTheNextPage:
        !isCustomerImported.value ||
        !existMatchingCustomers.value ||
        (existMatchingCustomers.value && confirmationFromExistingCustomer.value),
      onNextPageClick: ({ customer }) => {
        showConfirmationFromExistingCustomer.value = true
        customerCreationData.value = customer
        dealCreationData.customerId = customer.id
      },
      /* onPrevPage invokes even if stage is skipped */
      onPrevPageClick: () => {
        // isCustomerEditable.value = Boolean(props.payload.importedCustomer)
        // dealCreationData.customerId = null
        // customerCreationData.value = {}
      },
      payload: {
        customers: existingCustomers.value,
        importedCustomer: props.payload.importedCustomer,
        deals: existingCustomersDeals.value,
        showConfirmationFromExistingCustomer: showConfirmationFromExistingCustomer.value,
        changeConfirmation: ({
          confirm,
          customerOverwrite,
          overwriteStandSize,
          overwriteStandname,
          overwriteElements,
          dealId,
          customer
        }) => {
          showConfirmationFromExistingCustomer.value = false
          confirmationFromExistingCustomer.value = confirm
          if (confirm) {
            overwriteCustomer.value = customerOverwrite
            overwriteDeal.value = dealId
            selectedMatchingCustomer.value = customer
            standSizeOverwrite.value = overwriteStandSize
            standnameOverwrite.value = overwriteStandname
            elementsToOverwrite.value = overwriteElements
            if (!dealId) {
              alreadyExistDealContacts.value = []
            }
            nextTick(() => {
              multiStepsProviderRef.value.applyNextPage()
            })
          }
        }
      }
    },
    [DEAL_CREATION_MODAL.CUSTOMER_CREATION_MODAL]: {
      stage: 4,
      title: "customer",
      component: CustomerCreation,
      next: DEAL_CREATION_MODAL.DEAL_CONTACTS_MODAL,
      previous: !isCustomerPreselected.value
        ? DEAL_CREATION_MODAL.CUSTOMER_SEARCH_MODAL
        : DEAL_CREATION_MODAL.EVENT_SELECTION_MODAL,
      skip: Boolean(dealCreationData.customerId), // skip if customer exists (including preselected)
      isInvisible: true, // customer creation step is always treat as part of searching
      consentToTheNextPage: true,
      excludeNextStageFromCache: true, // don't keep alive contacts
      onNextPageClick: ({ customer }) => {
        customerCreationData.value = customer
      },
      /* onPrevPage invokes even if stage is skipped */
      onPrevPageClick: () => {
        isCustomerEditable.value = Boolean(props.payload.importedCustomer)
        dealCreationData.customerId = null
        customerCreationData.value = {}
      },
      payload: {
        customer: props.payload.importedCustomer ?? customerCreationData.value,
        isReadOnly: Boolean(!isCustomerEditable.value),
        isBbreg: !customerCreationData.value?.vismaCompanyId,
        companyName: searchedCompanyName.value
      }
    },
    [DEAL_CREATION_MODAL.DEAL_CONTACTS_MODAL]: {
      stage: 5,
      title: "contact",
      component: DealContactPersons,
      next: DEAL_CREATION_MODAL.DEAL_DETAILS_MODAL,
      previous: prevComponentFromDealContact.value,
      // User can't go back to customer if deal is created
      isPrevButtonDisabled: Boolean(createdDealId.value),
      prevButtonTooltip: "You cannot select another customer for an already created deal",
      consentToTheNextPage: true,
      payload: {
        contacts: props.payload.importedContact
          ? mergeContacts(props.payload.importedContact, alreadyExistDealContacts.value)
          : [...alreadyExistDealContacts.value],
        previousContacts: previousContacts.value
      },
      onNextPageClick: ({ contacts }) => {
        contactsCreationData.value = deepClone(contacts).map((contact) => {
          delete contact.__typename
          delete contact.validatedPhoneNumber
          return {
            ...contact,
            is_mystand_admin: !!contact.is_mystand_admin
          }
        })
      },
      onPrevPageClick: () => {
        if (dealCreationData.customerId) {
          dealCreationData.customerId = null
        }
        showConfirmationFromExistingCustomer.value = false
        confirmationFromExistingCustomer.value = false
        contactsCreationData.value = []
      }
    },
    [DEAL_CREATION_MODAL.DEAL_DETAILS_MODAL]: {
      stage: 6,
      title: "details",
      component: DealDetails,
      next: null,
      previous: DEAL_CREATION_MODAL.DEAL_CONTACTS_MODAL,
      isPrevButtonDisabled: areContactsCreated.value,
      prevButtonTooltip: "Everything up to the contacts has been created",
      consentToTheNextPage: true,
      payload: {
        teamMembers: teamMembers.value,
        preselectedDealResponsibleId:
          isDashboard && isAdmin.value
            ? impersonateUserId
            : overwriteDeal.value
            ? overwriteDealInformation.value?.responsibleId
            : isAdmin.value
            ? null
            : userId,
        isDealResponsibleReadOnly: !!overwriteDeal.value || (isDashboard && !isAdmin.value),
        preselectedActivityResponsibleId: !isAdmin.value ? userId : null,
        isActivityResponsibleReadOnly: !!overwriteDeal.value || !isAdmin.value,
        isDealDisabled: createdDealId.value,
        isActivityDisabled: !!overwriteDeal.value || isActivityCreated.value,
        availableTags: eventTags.value,
        showActivities: !overwriteDeal.value,
        preselectedTags: overwriteDeal.value ? getCombinedTags.value : props.payload.importedTags ?? [],
        valueNok: overwriteDeal.value ? overwriteDealInformation.value?.value_nok : 0,
        preselectedComment: getDealComment
      },
      onNextPageClick: onApplyClickHandler
    }
  }
}

const onUpdateCustomer = () => {
  const input = {
    id: selectedMatchingCustomer.value.id,
    company_name: elementsToOverwrite.value?.overwriteCustomerName
      ? props.payload.importedCustomer.companyName
      : selectedMatchingCustomer.value.company_name,
    company_city: elementsToOverwrite.value?.overwriteCustomerAddress
      ? props.payload.importedCustomer.companyCity
      : selectedMatchingCustomer.value.company_city,
    company_zip: elementsToOverwrite.value?.overwriteCustomerAddress
      ? props.payload.importedCustomer.companyZip
      : selectedMatchingCustomer.value.company_zip,
    company_countrycode: elementsToOverwrite.value?.overwriteCustomerCountry
      ? props.payload.importedCustomer.companyCountrycode
      : selectedMatchingCustomer.value.company_countrycode
  }

  if (elementsToOverwrite.value?.overwriteCustomerEmail) {
    input.invoice_email = props.payload.importedCustomer.invoiceEmail
  }

  if (elementsToOverwrite.value?.overwriteCustomerAddress) {
    input.company_street_address = props.payload.importedCustomer.companyStreetAddress
  }

  if (elementsToOverwrite.value?.overwriteCustomerIdentificator) {
    input.company_identificator = props.payload.importedCustomer.companyIdentificator
  }

  if (elementsToOverwrite.value?.overwriteCustomerUrl) {
    input.company_url = props.payload.importedCustomer.companyUrl
  }

  updateCustomer({ input })
}

const standPriceVariables = ref({})
const standPriceQueryOptions = ref({
  enabled: false
})

const { onResult: onStandPriceResult } = useQuery(STAND_PRICE, standPriceVariables, standPriceQueryOptions)
onStandPriceResult(({ data: { getStandPriceCollection: standPriceCollectionResult } }) => {
  nextTick(() => {
    if (standPriceCollectionResult !== null) {
      // Update stand
      const newArea = props.payload.importedCustomer.standSizeLength * props.payload.importedCustomer.standSizeWidth
      const inputData = {
        id: overwriteDealInformation.value.stand.id,
        registration_fee_type: overwriteDealInformation.value.stand.registration_fee_type,
        registration_fee_nok: overwriteDealInformation.value.stand.registration_fee_nok,
        discount_type: overwriteDealInformation.value.stand.discount_type,
        discount: overwriteDealInformation.value.stand.discount,
        length: props.payload.importedCustomer.standSizeLength || 0,
        width: props.payload.importedCustomer.standSizeWidth || 0,
        total_sqm: newArea,
        invoiced_total_sqm: newArea,
        area_pricing_group_id: overwriteDealInformation.value.stand.area_pricing_group_id,
        price_nok: standPriceCollectionResult.price_calculated_after_discount
      }
      if (standnameOverwrite.value) {
        inputData.standname_no = props.payload.importedCustomer?.standname
        inputData.standname_en = props.payload.importedCustomer?.standname
      }
      updateStand({ input: inputData })
    }
    standPriceQueryOptions.value.enabled = false
  })
})

const onApplyClickHandler = ({ deal, activity }) => {
  activityCreationData.value = activity
  dealCreationData.responsibleId = deal.responsibleId ?? null
  dealCreationData.value_nok = deal.value_nok ?? 0
  dealCreationData.signupComment = deal.signupComment ?? ""
  dealTags.value = deal.tags ?? []

  if (overwriteCustomer.value) {
    onUpdateCustomer()
  }

  // Deal creation branching: creation of customer (optional) -> deal -> activity (optional) + contact persons (optional)
  if (overwriteDeal.value) {
    if (standSizeOverwrite.value) {
      // Calculate new price (and update stand in onStandPriceResult)
      standPriceVariables.value = {
        invoiced_total_sqm:
          props.payload.importedCustomer.standSizeLength * props.payload.importedCustomer.standSizeWidth,
        area_pricing_group_id: overwriteDealInformation.value.stand.area_pricing_group_id,
        discount_type: overwriteDealInformation.value.stand.discount_type,
        discount: overwriteDealInformation.value.stand.discount,
        registration_fee_type: overwriteDealInformation.value.stand.registration_fee_type,
        registration_fee_nok: overwriteDealInformation.value.stand.registration_fee_nok,
        event_id: eventId,
        stand_id: overwriteDealInformation.value.stand.id
      }
      standPriceQueryOptions.value.enabled = true
    } else if (standnameOverwrite.value) {
      // Update stand without recalculating price
      const inputData = {
        id: overwriteDealInformation.value.stand.id,
        registration_fee_type: overwriteDealInformation.value.stand.registration_fee_type,
        registration_fee_nok: overwriteDealInformation.value.stand.registration_fee_nok,
        discount_type: overwriteDealInformation.value.stand.discount_type,
        discount: overwriteDealInformation.value.stand.discount,
        standname_no: props.payload.importedCustomer?.standname,
        standname_en: props.payload.importedCustomer?.standname
      }
      updateStand({ input: inputData })
    }

    updateDeal({
      input: {
        id: overwriteDeal.value,
        user_id: dealCreationData.responsibleId,
        value_nok: dealCreationData.value_nok,
        signup_comment: dealCreationData.signupComment
      }
    })
  } else if (!dealCreationData.customerId && !overwriteCustomer.value) {
    createCustomer(customerCreationData.value)
  } else if ((!createdDealId.value && !overwriteDeal.value) || (existMatchingCustomers.value && !overwriteDeal.value)) {
    createDeal(getDealCreationInput())
  } else {
    // NOTE: activity or contacts creation is detected by areContactsCreated and isActivityCreated
    createDealDependenciesSync()
  }
}

const closeModal = () => {
  // nextTick helps to wait till req finishes with loading set to false
  nextTick(() => {
    store.ui.methods.setModalWindowState(null, true)
  })
}

updateStagesData()

// TODO: invent better reactive way to update only required parts of stages
watchEffect(() => {
  updateStagesData()
})

onUnmounted(() => {
  // unsubscribe from customer creation onDone and onError hooks that are defined outside of component and alive
  customerCreationWatcher.off()
  customerErrorWatcher.off()
})

const showLoading = computed(() => {
  return (
    loadingCustomers.value ||
    loadingExistingDealContacts.value ||
    loadingExistingDeals.value ||
    loadingMembers.value ||
    loadingPreSelCustomer.value
  )
})
</script>
