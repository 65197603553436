<template>
  <div class="max-h-[460px] overflow-auto">
    <div class="flex-col mr-2">
      <div v-if="props.payload.deals?.length" class="flex-col mt-3">
        <label class="text-normal normal-case">Update existing deal for this customer</label>

        <div :class="cardStyles" v-for="(deal, index) in props.payload.deals" :key="deal.id">
          <div class="flex flex-col p-1 w-3/5">
            <router-link
              v-if="deal.exhibitor.id"
              :to="{ name: 'customer', params: { id: deal.exhibitor.id } }"
              target="_blank"
              class="text-link underline w-fit"
            >
              {{ deal.exhibitor.company_name }}
            </router-link>
            <span class="description"> {{ deal.exhibitor.company_street_address }} </span>

            <div class="flex items-center">
              <span class="description"> Visma ID {{ deal.exhibitor.visma_company_id }} </span>
              <span v-if="deal.exhibitor.company_identificator" class="description ml-3">
                Org.no {{ deal.exhibitor.company_identificator }}
              </span>
            </div>
          </div>

          <div class="flex-col py-2">
            <div class="flex items-center justify-between w-full cursor-pointer" @click="existingDealSelected(deal.id)">
              <div
                class="flex items-center justify-between text-sm border border-gray-500 p-2 w-3/4"
                :class="{ 'mt-2': index }"
              >
                <span>{{ deal.stand.standname }}</span>

                <DealStageCell
                  class="ml-2"
                  :payload="{
                    stage: deal.stage,
                    status: deal.stand?.status
                  }"
                />
              </div>

              <input
                class="mr-10 w-5 h-5 cursor-pointer ml-3"
                type="radio"
                name="deal"
                :checked="isDealSelected === deal.id"
                :value="deal.id"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="flex-col mt-5">
        <label class="text-normal normal-case">Create new deal for this customer</label>

        <div v-for="customer in props.payload.customers" :key="customer.id">
          <ExistingListItem
            with-selection
            :id="customer.id"
            :customer="{
              id: customer.id,
              vismaCompanyId: customer.visma_company_id,
              companyIdentificator: customer.company_identificator,
              companyName: customer.company_name,
              companyStreetAddress: customer.company_street_address
            }"
            :selectedItemId="createNewDeal"
            @selectionChange="newDealSelected(customer.id)"
          />
        </div>
      </div>
    </div>

    <div
      v-if="props.payload.showConfirmationFromExistingCustomer"
      class="absolute w-full h-full right-0 top-0 bg-white flex justify-center p-6 overflow-y-auto"
    >
      <div class="flex flex-col justify-between items-center">
        <div>
          <div v-if="isDealSelected">
            <div class="text-center text-lg font-semibold py-2">Update existing deal from Signup</div>
            <div v-if="isStandDetailsDifferent || isCompanyDetailsDifferent" class="py-4">
              <div class="flex items-center border-y border-gray-300 py-3">
                <svg-icon classNames="text-blue-700 h-6 w-1/12" name="info"></svg-icon>
                <div class="text-sm w-11/12 border border-blue-500 p-2 mr-2 bg-blue-100">
                  <span class="font-semibold">Keep original values?</span>
                  <span>
                    To keep original values, untick the boxes. To update with new information from sign-up, leave boxes
                    ticked.
                  </span>
                </div>
              </div>
            </div>
            <div v-else>Please confirm that you want to update the existing deal from this signup</div>
          </div>
          <div v-else>
            <div class="text-center text-lg font-semibold py-2">Create a new deal for this customer</div>
            <div v-if="isCompanyDetailsDifferent" class="py-4">
              <div class="flex items-center border-y border-gray-300 py-3">
                <svg-icon classNames="text-blue-700 h-6 w-1/12" name="info"></svg-icon>
                <div class="text-sm w-11/12 border border-blue-500 p-2 mr-2 bg-blue-100">
                  <span class="font-semibold">Some customer details have changed.</span>
                  <span>
                    To keep original values, untick the boxes. To update with new information from sign-up, leave boxes
                    ticked.
                  </span>
                </div>
              </div>
            </div>
            <div v-else>Please confirm that you want to create a new deal from this signup</div>
          </div>

          <div class="flex-center">
            <div v-if="isDealSelected" class="pb-4 border-b border-gray-300">
              <div v-if="isStandDetailsDifferent" class="text-indigo-700 font-semibold pt-4">Stand information</div>

              <div v-if="isStandSizeDifferent" class="flex-col pt-2">
                <div class="text-sm">
                  <span>Length and depth</span>
                  <span class="text-gray-500 pl-2">Note: Stand price will be adjusted</span>
                </div>

                <div class="flex items-center justify-between m-auto w-11/12">
                  <label
                    for="update-length-depth"
                    class="flex w-full text-normal normal-case font-normal cursor-pointer ml-2 mr-3"
                  >
                    <div class="text-gray-500 w-5/12 break-all">
                      {{ selectedExistingDeal.stand.length }}m x {{ selectedExistingDeal.stand.width }}m
                    </div>

                    <div class="w-2/12 text-center">=></div>

                    <div class="w-5/12 break-all font-semibold">
                      {{ props.payload.importedCustomer?.standSizeLength || 0 }}m x
                      {{ props.payload.importedCustomer?.standSizeWidth || 0 }}m
                    </div>
                  </label>

                  <input
                    v-model="overwriteStandSize"
                    id="update-length-depth"
                    class="checkbox h-5 w-5"
                    type="checkbox"
                  />
                </div>
              </div>

              <div v-if="isStandnameDifferent" class="flex-col pt-2">
                <div class="text-sm">Stand name</div>

                <div class="flex items-center justify-between m-auto w-11/12">
                  <label
                    for="update-standname"
                    class="flex w-full text-normal normal-case font-normal cursor-pointer ml-2 mr-3"
                  >
                    <div class="w-5/12 text-gray-500 break-all">
                      {{ selectedExistingDeal.stand.standname || "-" }}
                    </div>

                    <div class="w-2/12 text-center">=></div>

                    <div class="w-5/12 break-all font-semibold">
                      {{ props.payload.importedCustomer?.standname }}
                    </div>
                  </label>

                  <input v-model="overwriteStandname" id="update-standname" class="checkbox h-5 w-5" type="checkbox" />
                </div>
              </div>
            </div>

            <div v-if="isCompanyDetailsDifferent" class="pt-4">
              <span class="text-indigo-700 font-semibold">Customer information</span>
              <span class="text-sm text-gray-500 pl-2">Note: This information is used across all projects</span>
            </div>

            <!-- company with matching name, but not matching orgNo -->
            <!-- Give warning as it might be wrong company. Cannot update orgNo  -->
            <div
              class="flex-col text-sm"
              v-if="!matchingCustomerByOrgNo && matchingCustomerByCompanyName && customer.company_identificator"
            >
              <div class="flex items-center">
                <div class="flex items-center py-3">
                  <svg-icon classNames="h-5 w-1/12" name="warning"></svg-icon>
                  <div class="w-11/12">
                    <span class="text-red-700">
                      The customer you have selected has the same name, but a different organization number than the one
                      given by the Signup. If you choose to update this customer, org.no will remain
                      {{ customer.company_identificator }}.
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="isCompanyNamesDifferent" class="flex-col">
              <span class="text-sm">Company Name</span>

              <div class="flex items-center justify-between m-auto w-11/12">
                <label
                  for="update-customer-name"
                  class="flex w-full text-normal normal-case font-normal cursor-pointer ml-2 mr-3"
                >
                  <div class="w-5/12 break-all text-gray-500">{{ customer?.company_name || "-" }}</div>

                  <div class="w-2/12 text-center">=></div>

                  <div class="w-5/12 break-all font-semibold">
                    {{ props.payload.importedCustomer?.companyName || "-" }}
                  </div>
                </label>

                <input
                  v-model="overwriteCustomerName"
                  id="update-customer-name"
                  class="checkbox h-5 w-5"
                  type="checkbox"
                />
              </div>
            </div>

            <div v-if="isEmailDifferent" class="flex-col pt-2">
              <span class="text-sm">Invoice email</span>

              <div class="flex items-center justify-between m-auto w-11/12">
                <label
                  for="update-customer-email"
                  class="flex w-full text-normal normal-case font-normal cursor-pointer ml-2 mr-3"
                >
                  <div class="w-5/12 break-all text-gray-500">{{ customer?.invoice_email || "-" }}</div>

                  <div class="w-2/12 text-center">=></div>

                  <div class="w-5/12 break-all font-semibold">
                    {{ props.payload.importedCustomer?.invoiceEmail || "-" }}
                  </div>
                </label>

                <input
                  v-model="overwriteCustomerEmail"
                  id="update-customer-email"
                  class="checkbox h-5 w-5"
                  type="checkbox"
                />
              </div>
            </div>

            <div v-if="isStreetAddressDifferent || isCityDifferent || isZipDifferent" class="flex-col pt-2">
              <span class="text-sm">Address</span>

              <div class="flex items-center justify-between m-auto w-11/12">
                <label
                  for="update-customer-address"
                  class="flex w-full text-normal normal-case font-normal cursor-pointer ml-2 mr-3"
                >
                  <div class="w-5/12 text-gray-500">
                    {{ customer?.company_zip }}, {{ customer?.company_street_address }},
                    {{ customer?.company_city }}
                  </div>

                  <div class="w-2/12 text-center">=></div>

                  <div class="w-5/12 font-semibold">
                    {{ props.payload.importedCustomer?.companyZip }},
                    {{ props.payload.importedCustomer?.companyStreetAddress }},
                    {{ props.payload.importedCustomer?.companyCity }}
                  </div>
                </label>

                <input
                  v-model="overwriteCustomerAddress"
                  id="update-customer-address"
                  class="checkbox h-5 w-5"
                  type="checkbox"
                />
              </div>
            </div>

            <div v-if="isCountryDifferent" class="flex-col pt-2">
              <span class="text-sm">Country</span>

              <div class="flex items-center justify-between m-auto w-11/12">
                <label
                  for="update-customer-country"
                  class="flex w-full text-normal normal-case font-normal cursor-pointer ml-2 mr-3"
                >
                  <div class="w-5/12 text-gray-500">
                    {{ customer?.company_countrycode ? customerCountry.name : "-" }}
                  </div>

                  <div class="w-2/12 text-center">=></div>

                  <div class="w-5/12 font-semibold">
                    {{ props.payload.importedCustomer?.companyCountrycode ? importedCustomerCountry.name : "-" }}
                  </div>
                </label>

                <input
                  v-model="overwriteCustomerCountry"
                  id="update-customer-country"
                  class="checkbox h-5 w-5"
                  type="checkbox"
                />
              </div>
            </div>

            <div v-if="isUrlDifferent" class="flex-col pt-2">
              <span class="text-sm">Company website</span>

              <div class="flex items-center justify-between m-auto w-11/12">
                <label
                  for="update-customer-url"
                  class="flex w-full text-normal normal-case font-normal cursor-pointer ml-2 mr-3"
                >
                  <div class="w-5/12 text-gray-500">{{ customer?.company_url || "-" }}</div>

                  <div class="w-2/12 text-center">=></div>

                  <div class="w-5/12 font-semibold">{{ props.payload.importedCustomer?.companyUrl || "-" }}</div>
                </label>

                <input
                  v-model="overwriteCustomerUrl"
                  id="update-customer-url"
                  class="checkbox h-5 w-5"
                  type="checkbox"
                />
              </div>
            </div>
            <!-- Can only update orgNo if it is empty in database -->
            <div
              v-if="matchingCustomerByCompanyName && !customer.company_identificator && isOrgNoDifferent"
              class="flex-col"
            >
              <span class="text-sm">Org.No</span>

              <div class="flex items-center justify-between m-auto w-11/12">
                <label
                  for="update-customer-identificator"
                  class="flex w-full text-normal normal-case font-normal cursor-pointer ml-2 mr-3"
                >
                  <div class="w-5/12 text-gray-500">{{ customer?.company_identificator || "-" }}</div>

                  <div class="w-2/12 text-center">=></div>

                  <div class="w-5/12 font-semibold">
                    {{ props.payload.importedCustomer?.companyIdentificator || "-" }}
                  </div>
                </label>

                <input
                  v-model="overwriteCustomerIdentificator"
                  id="update-customer-identificator"
                  class="checkbox h-5 w-5"
                  type="checkbox"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="flex justify-center space-x-2 pb-2 mt-8">
          <button class="button button-bg-light" @click="confirmationChanged(false)">Cancel</button>
          <button class="button" @click="confirmationChanged(true)">Confirm</button>
        </div>
      </div>
    </div>
  </div>
</template>

<!-- export name for cache (<keep-alive />) -->
<script>
export default {
  name: "ExistingCustomerDeal"
}
</script>

<script setup>
import { computed, reactive, ref } from "vue"
import { deepClone } from "@/utils/helpers"
import { twMerge } from "tailwind-merge"
import { useCountryService, findCustomerCountry } from "@/utils/countryService"
import DealStageCell from "@/components/BaseDealsTable/DynamicDealsTableCells/dealStageCell.vue"
import ExistingListItem from "../TheModalWindow/CustomerSearching/SearchListItems/existingListItem.vue"

const { countries, isLoaded } = useCountryService()

const props = defineProps({
  payload: {
    type: Object,
    required: false,
    default: () => ({
      customers: [],
      importedCustomer: null,
      deals: [],
      showConfirmationFromExistingCustomer: false,
      insertCustomerDeal: () => {},
      changeConfirmation: () => {}
    })
  }
})

let customer = reactive(deepClone(props.payload.customers[0]) || {})
// Set 'update existing deal' as default selection, if a deal exists
const isDealSelected = ref(props.payload.deals[0]?.id)
const selectedExistingDeal = computed(() => {
  if (isDealSelected.value) {
    return props.payload.deals?.find((el) => el.id === isDealSelected.value)
  }

  return null
})
// Set 'create new deal' as default selection, if a deal doesn't exist, but a customer does
const createNewDeal = ref(props.payload.deals[0]?.id ? null : props.payload.customers[0]?.id)
const matchingCustomerByOrgNo = computed(() => {
  return customer.company_identificator === props.payload.importedCustomer?.companyIdentificator
})

const matchingCustomerByCompanyName = computed(() => {
  return customer.company_name.toLowerCase() === props.payload.importedCustomer?.companyName.toLowerCase()
})

const overwriteStandSize = ref(true)
const overwriteStandname = ref(true)
const overwriteCustomerName = ref(true)
const overwriteCustomerEmail = ref(true)
const overwriteCustomerAddress = ref(true)
const overwriteCustomerCountry = ref(true)
const overwriteCustomerUrl = ref(true)
const overwriteCustomerIdentificator = ref(true)

const overwriteCustomer = computed(() => {
  return (
    overwriteCustomerName.value ||
    overwriteCustomerEmail.value ||
    overwriteCustomerAddress.value ||
    overwriteCustomerCountry.value ||
    overwriteCustomerUrl.value ||
    overwriteCustomerIdentificator.value
  )
})

const isStandSizeDifferent = computed(
  () =>
    selectedExistingDeal.value.stand.length !== props.payload.importedCustomer?.standSizeLength ||
    selectedExistingDeal.value.stand.width !== props.payload.importedCustomer?.standSizeWidth
)
const isStandnameDifferent = computed(
  () => selectedExistingDeal.value.stand.standname !== props.payload.importedCustomer?.standname
)
const isCompanyNamesDifferent = computed(() => customer?.company_name !== props.payload.importedCustomer?.companyName)
const isEmailDifferent = computed(() => customer?.invoice_email !== props.payload.importedCustomer?.invoiceEmail)
const isStreetAddressDifferent = computed(
  () => customer?.company_street_address !== props.payload.importedCustomer?.companyStreetAddress
)
const isCityDifferent = computed(() => customer?.company_city !== props.payload.importedCustomer?.companyCity)
const isZipDifferent = computed(() => customer?.company_zip !== props.payload.importedCustomer?.companyZip)
const isCountryDifferent = computed(
  () => customer?.company_countrycode !== props.payload.importedCustomer?.companyCountrycode
)
const isOrgNoDifferent = computed(
  () => customer?.company_identificator !== props.payload.importedCustomer?.companyIdentificator
)
const isUrlDifferent = computed(() => customer?.company_url !== props.payload.importedCustomer?.companyUrl)

const isStandDetailsDifferent = computed(() => isStandnameDifferent.value || isStandSizeDifferent.value)

const isCompanyDetailsDifferent = computed(
  () =>
    isCompanyNamesDifferent.value ||
    isEmailDifferent.value ||
    isStreetAddressDifferent.value ||
    isCityDifferent.value ||
    isZipDifferent.value ||
    isCountryDifferent.value ||
    isUrlDifferent.value ||
    (!matchingCustomerByOrgNo.value && isOrgNoDifferent.value)
)

const cardStyles = computed(() => {
  const borderColor = isDealSelected.value ? "!border-indigo-500" : "border-gray-300 hover:border-indigo-500"
  const cursor = props.withSelection ? "cursor-pointer" : ""
  return twMerge("card-square flex justify-between items-center mt-3 p-1", borderColor, cursor)
})

const customerCountry = computed(() => {
  if (isLoaded.value) {
    return findCustomerCountry(customer.company_countrycode, countries)
  }
  return customer.company_countrycode
})

const importedCustomerCountry = computed(() => {
  if (isLoaded.value) {
    return findCustomerCountry(props.payload.importedCustomer.companyCountrycode, countries)
  }
  return props.payload.importedCustomer.companyCountrycode
})

const confirmationChanged = (confirm) => {
  const overwriteElements = {
    overwriteCustomerName: overwriteCustomerName.value,
    overwriteCustomerEmail: overwriteCustomerEmail.value,
    overwriteCustomerAddress: overwriteCustomerAddress.value,
    overwriteCustomerCountry: overwriteCustomerCountry.value,
    overwriteCustomerUrl: overwriteCustomerUrl.value,
    overwriteCustomerIdentificator: overwriteCustomerIdentificator.value
  }

  props.payload.changeConfirmation({
    confirm,
    customerOverwrite: overwriteCustomer.value,
    overwriteStandSize: overwriteStandSize.value,
    overwriteStandname: overwriteStandname.value,
    overwriteElements,
    dealId: isDealSelected.value,
    customer
  })
}

const existingDealSelected = (dealId) => {
  isDealSelected.value = dealId
  createNewDeal.value = ""
  customer = reactive(deepClone(props.payload.deals.find((deal) => deal.id === dealId)).exhibitor)
}

const newDealSelected = (customerId) => {
  isDealSelected.value = null
  createNewDeal.value = customerId
  customer = reactive(deepClone(props.payload.customers.find((customer) => customer.id === customerId)))
}

defineExpose({
  customer
})
</script>
