import { provideApolloClient, useQuery, useMutation } from "@vue/apollo-composable"
import apolloClient from "@/utils/apolloProvider"
import VALIDATE_MOBILE_NUMBER from "@/constants/graphql/mutations/validateMobileNumber.gql"
import MOBILE_COUNTRY_CODES from "@/constants/graphql/queries/mobileCountryCodes.gql"

provideApolloClient(apolloClient)

const { mutate: validateNumber } = useMutation(VALIDATE_MOBILE_NUMBER, () => ({
  throws: "never"
}))

export const validateMobileNumber = async (phoneNumber) => {
  const res = await validateNumber({ fullNumber: phoneNumber })
  if (res?.data?.validateMobileNumber.isValid) {
    return {
      isValid: res.data.validateMobileNumber.isValid,
      countryCode: res.data.validateMobileNumber.countryCode,
      number: res.data.validateMobileNumber.number,
      fullNumber: res.data.validateMobileNumber.fullNumber
    }
  } else {
    return false
  }
}

export const getCountryCodes = async () => {
  return new Promise((resolve) => {
    const { onResult: onCountryCodes } = useQuery(MOBILE_COUNTRY_CODES, () => ({
      fetchPolicy: "cache-first"
    }))
    onCountryCodes(({ data: { getMobileCountryCodes } }) => {
      if (getMobileCountryCodes) {
        resolve(getMobileCountryCodes)
      } else {
        resolve(false)
      }
    })
  })
}
