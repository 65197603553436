<template>
  <div v-click-outside="cancelAction" class="flex flex-col space-y-5 text-start">
    <div class="pt-5 px-10 flex flex-col items-center text-normal normal-case">
      <div class="pb-5 heading-2 text-center">Unsell Deal</div>

      <div class="flex flex-col items-center">
        <div v-if="props.payload.isInvoiced" class="flex pt-2">
          <span class="pb-2 font-semibold">This deal has been invoiced</span>
        </div>

        <div v-if="props.payload.isInvoiced" class="flex flex-col items-center">
          <div class="flex items-center">
            <input
              id="invoiced"
              type="checkbox"
              class="checkbox h-5 w-5 mr-2"
              :checked="cancelInvoice"
              @change="onChange"
            />
            <label for="invoiced" class="cursor-pointer">Cancel the invoice</label>
          </div>
        </div>
      </div>

      <div class="flex flex-col items-start pt-4">
        <div v-if="cancelInvoice" class="flex pb-1">
          <svg-icon class="w-10 h-10" name="warning" disabled></svg-icon>
          <span class="pl-2">Contact Finance to send a credit note in Visma</span>
        </div>
        <div class="flex">
          <svg-icon class="w-10 h-10" name="warning" disabled></svg-icon>
          <span class="pl-2">Inform drawing responsible that deal is cancelled</span>
        </div>
      </div>
    </div>
    <div class="py-5 flex justify-center space-x-2">
      <button class="button button-bg-light" @click="cancelAction">Cancel</button>
      <button class="button" @click="confirmAction">Unsell</button>
    </div>
  </div>
</template>

<script setup>
import { inject, ref } from "vue"

const cancelInvoice = ref(false)

const onChange = () => {
  cancelInvoice.value = !cancelInvoice.value
}

const props = defineProps({
  payload: {
    type: Object,
    default: () => ({
      isInvoiced: true,
      confirmAction: () => {},
      cancelAction: () => {}
    })
  }
})
const store = inject("store")

const closeModal = () => {
  store.ui.methods.setModalWindowState()
}

const cancelAction = () => {
  props.payload.cancelAction?.()
  closeModal()
}

const confirmAction = () => {
  if (props.payload.isInvoiced) {
    props.payload.confirmAction?.(cancelInvoice.value)
  } else {
    props.payload.confirmAction?.()
  }
  closeModal()
}
</script>
