/* Tables names are used for storing page table settings */
export default {
  DASHBOARD_DEALS_TABLE: "DASHBOARD_DEALS_TABLE",
  PROJECT_DEALS_TABLE: "PROJECT_DEALS_TABLE",
  CUSTOMER_DEALS_TABLE: "CUSTOMER_DEALS_TABLE",
  DASHBOARD_ACTIVITIES_TABLE: "DASHBOARD_ACTIVITIES_TABLE",
  PROJECT_ACTIVITIES_TABLE: "PROJECT_ACTIVITIES_TABLE",
  DEAL_ACTIVITIES_TABLE: "DEAL_ACTIVITIES_TABLE",
  CUSTOMER_ACTIVITIES_TABLE: "CUSTOMER_ACTIVITIES_TABLE",
  SETTINGS_TABLE: "SETTINGS_TABLE",
  CUSTOMERS_TABLE: "CUSTOMERS_TABLE",
  TEAMS_TABLE: "TEAMS_TABLE",
  MEMBERS_TABLE: "MEMBERS_TABLE",
  EXTRA_ORDERS_TABLE: "EXTRA_ORDERS_TABLE",
  EXHIBITOR_SIGNUPS_TABLE: "EXHIBITOR_SIGNUPS_TABLE",
  PROJECT_INVOICING: "PROJECT_INVOICING",
  MODEL_REVISIONS_TABLE: "MODEL_REVISIONS_TABLE",
  MODEL_REVISION_TABLE: "MODEL_REVISION_TABLE"
}
