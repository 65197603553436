<template>
  <div class="flex items-center">
    <label class="flex items-center cursor-pointer space-x-2">
      <div class="relative">
        <input
          type="checkbox"
          class="sr-only"
          :value="modelValue"
          :checked="modelValue"
          @change="(event) => emit('update:modelValue', event.target.checked)"
          :disabled="disabled"
        />
        <div class="block w-14 h-8 rounded-full" :class="bgColor"></div>
        <div class="dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition duration-500" />
      </div>
      <div v-if="label" class="label">{{ props.label }}</div>
    </label>
  </div>
</template>

<script setup>
import { computed } from "vue"
const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
    required: true
  },
  label: { type: String, required: false },
  disabled: { type: Boolean, required: false, default: false }
})

const bgColor = computed(() => {
  if (props.modelValue) {
    if (props.disabled) {
      return "bg-blue-500 opacity-30"
    } else {
      return "bg-blue-500 hover:bg-blue-700"
    }
  } else {
    if (props.disabled) {
      return "bg-gray-500 opacity-30"
    } else {
      return "bg-gray-400 hover:bg-gray-600"
    }
  }
})

const emit = defineEmits(["update:modelValue"])
</script>

<style scoped lang="postcss">
input:checked ~ .dot {
  transform: translateX(100%);
}
</style>
